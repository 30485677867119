.opacity-0 {
  opacity:0!important;
}
.opacity-1 {
  opacity:0.2!important;
}
.opacity-2 {
  opacity:0.4!important;
}
.opacity-3 {
  opacity:0.6!important;
}
.opacity-4 {
  opacity:.8!important;
}
.opacity-5 {
  opacity:1!important;
}

/* Maybe even support hover opacity shifts */
.opacity-0h5 {
  opacity:0!important;
  transition: opacity .25s ease-in-out!important;
  -moz-transition: opacity .25s ease-in-out!important;
  -webkit-transition: opacity .25s ease-in-out!important;
}
.opacity-0h5:hover {
  opacity:1!important;
}

.rounded-xl {
  border-radius: 0.375em !important;
}

// overriding badge dot size
.badge.badge-dot {
  width: .8em !important;
  height: .8em !important;
}

// Fix uneven padding top on badge in some cases
.badge-pt-fix {
  padding-top: 0.25rem;
}

.custom-checkbox {
  &.no-label {
    margin-left: 13px;
    margin-right: 0;
  }
}

.custom-control-label {
  vertical-align: middle;
}
